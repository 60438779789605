<template>
  <div class="playPageWrap en">
    <m-layout-bar>
      <div class="playWrap">
        <div class="bannerWrap">
          <img :src="require('../assets/image/play_en/banner.png')" alt />
          <!-- 轮播 -->
          <div id="bannerMenu" class="bannerBox iSwiperWrap">
            <div class="swiper-container gallery-thumbs">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide swiper-bottom"
                  :class="item.activeName"
                  v-for="(item,index) of bannerImg"
                  :key="index"
                >
                  <div class="menuThumbWrap" @click="goNavArea(item.id)">
                    <div class="imgWrap trans">
                      <img :src="require('../assets/image/play_en/'+item.url+'')" alt />
                    </div>
                    <div class="infoWrap">
                      <div class="gap"></div>

                      <span class="title">{{item.title}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Add Arrows -->
            <!-- <div class="btnWrap">
              <div class="swiper-button-next swiper-button-white"></div>
              <div class="swiper-button-prev swiper-button-white"></div>
            </div>-->
          </div>
          <!-- 轮播end -->

          <!-- 吸顶菜单 -->
          <div class="fixedMenus" :class="barFixed?'show':''">
            <a
              class="fixedMenu liuLiuLeYuan trans"
              :class="{'active':activeName==='liuLiuLeYuan'}"
              data-name="liuLiuLeYuan"
              @click="clickMenuItem('gLiuLiuLeYuan',$event)"
            >
              <!-- <img :src="require('../assets/image/stay/star.png')" alt /> -->
              Fun Flower Land
            </a>
            <a
              class="fixedMenu shiHu trans"
              :class="{'active':activeName==='shiHu'}"
              data-name="shiHu"
              @click="clickMenuItem('gShiHuWenHuaYuan',$event)"
            >
              <!-- <img :src="require('../assets/image/stay/star.png')" alt /> -->
              Dendrobium Culture Park
            </a>
            <a
              class="fixedMenu nongGeng trans"
              :class="{'active':activeName==='nongGeng'}"
              data-name="nongGeng"
              @click="clickMenuItem('gNongGengLeYuan',$event)"
            >
              <!-- <img :src="require('../assets/image/stay/star.png')" alt /> -->
              Farming paradise
            </a>
          </div>
        </div>
        <!--1. Fun Flower Land -->
        <div id="gLiuLiuLeYuan" class="funlandWrap bigGroup layoutLeftWrap">
          <div class="content">
            <img
              class="banner shadowBox_center"
              :src="require('../assets/image/play_en/g1_banner.png')"
              alt
            />
            <div class="layoutRight">
              <img class="icon" :src="require('../assets/image/play_en/g1_icon.png')" alt />
              <span class="title">Fun Flower Land</span>
              <div class="detail">
                This is a place full of joy and childlike interest. It takes Dendrobium shape and tastes
                smooth Naming, which subverts the inherent concept of domestic unpowered parks,
                is currently the largest yo yo Park in ChinaThe theme park has three theme areas:
                Huahai Liuliu, world wind Liuliu and tiger LiuliuRisk, excitement, food and beauty.
              </div>
              <div class="btnsWrap">
                <div class="rowWrap">
                  <div class="btn">Tiger yo</div>
                  <div class="btn">Yo yo tower</div>
                </div>
                <div class="rowWrap">
                  <div class="btn">A sea of flowers</div>
                  <div class="btn">The world is windy</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--2. Dendrobium Culture Park -->
        <div id="gShiHuWenHuaYuan" class="cultureWrap bigGroup layoutRightWrap">
          <div class="content">
            <div class="layoutRight">
              <img class="icon" :src="require('../assets/image/play_en/g2_icon.png')" alt />
              <span class="title">Dendrobium Culture Park</span>
              <div class="detail">
                Dendrobium Cultural Park is a paradise tailored for every family. Children who like to
                play can learn in amusement school Learning Dendrobium knowledge and being close
                to pastoral nature in fun, beautiful mothers can enjoy professional skin care Experience
                and enjoy a relaxed shopping experience. Favorite dads can not only taste traditional
                Chinese dinner Exquisite Western style pastries, special tea and health wine are also
                available for tourists of all ages You can have a joy experience beyond imagination.
              </div>
              <div class="btnsWrap">
                <div class="rowWrap">
                  <div class="btn">Dendrobium forest</div>
                  <div class="btn">
                    Dendrobium Culture
                    Museum
                  </div>
                </div>
                <div class="rowWrap">
                  <div class="btn">
                    forget sorrow
                    Wine Culture Museum
                  </div>
                  <div class="btn">Worry free Tavern</div>
                </div>
              </div>
            </div>
            <img
              class="banner shadowBox_center"
              :src="require('../assets/image/play_en/g2_banner.png')"
              alt
            />
          </div>
        </div>

        <!--3. Farming paradis -->
        <div id="gNongGengLeYuan" class="farmingWrap bigGroup layoutLeftWrap">
          <div class="content">
            <img
              class="banner shadowBox_center"
              :src="require('../assets/image/play_en/g3_banner.png')"
              alt
            />
            <div class="layoutRight">
              <img class="icon" :src="require('../assets/image/play_en/g3_icon.png')" alt />
              <span class="title">Farming paradise</span>
              <div class="detail">
                The road after school is noisy and hip-hop, the water splashes between the ridges,
                the cool starry night after dinner, and the firefly breezeCrescent moon... Come on,
                walk into the farming paradise and take the time shuttle back to your beautiful
                childhood,Go to work in spring, work in summer, harvest in autumn and hide in winter
                , cut firewood, feed horses, carry water and cook. The dishes taste like vegetables,
                the meat smells like meat, and people are different There is warmth and trust between
                people. The common sight here is your dream of living in a farmhouse.
              </div>
              <div class="btnsWrap">
                <div class="rowWrap">
                  <div class="btn">Geng Le primary school</div>
                  <div class="btn">Light eating panicle rice</div>
                </div>
                <div class="rowWrap">
                  <div class="btn">Rural life home</div>
                  <div class="btn">Fruit bar</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar_en";
import { checkIsNumber } from "@/utils/util.js";

import Swiper from "swiper";

export default {
  components: {
    [LayoutBar.name]: LayoutBar
  },
  data() {
    return {
      activeName: "liuLiuLeYuan",
      barFixed: false,
      bannerImg: [
        {
          url: "c_1.png",
          title: "Fun Flower Land",
          activeName: "liuLiuLeYuan",
          id: "gLiuLiuLeYuan"
        },
        {
          url: "c_2.png",
          title: "Dendrobium Culture Park",
          activeName: "shiHu",
          id: "gShiHuWenHuaYuan"
        },
        {
          url: "c_3.png",
          title: "Farming paradise",
          activeName: "nongGeng",
          id: "gNongGengLeYuan"
        }
      ]
    };
  },
  created() {
    this.$nextTick(function() {
      this.galleryThumbsIns();
      // this.galleryTopIns();
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    goNavArea(anchorId) {
      let anchorElement = document.getElementById(anchorId);
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    },
    clickMenuItem(anchorId, e) {
      // console.log("e:",e);
      if (!e.currentTarget.getAttributeNode("data-name")) return;
      this.activeName = e.currentTarget.getAttributeNode("data-name").value;
      if (!this.activeName) {
        this.activeName = "";
      }
      this.goNavArea(anchorId);
    },
    galleryThumbsIns() {
      this.galleryThumbs = new Swiper(".gallery-thumbs", {
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev"
        // },
        spaceBetween: 10,
        slidesPerView: 3,
        loop: false,
        freeMode: true,
        loopedSlides: 4, //looped slides should be the same
        watchSlidesVisibility: true,
        watchSlidesProgress: true
      });
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (!document.querySelector("#bannerMenu")) return;
      let distOffsetTop = document.querySelector("#bannerMenu").offsetTop;

      if (parseInt(distOffsetTop).toString() === "NaN") {
        return;
      }

      if (scrollTop > distOffsetTop + 120) {
        this.barFixed = true;
      } else {
        this.barFixed = false;
      }

      //滚动到溜溜乐园的范围
      if (scrollTop > 670) {
        this.activeName = "liuLiuLeYuan";
      }
      // 滚动到石斛文化园的范围1416
      if (scrollTop > 1150) {
        this.activeName = "shiHu";
      }

      //滚动到农耕乐园的范围2435 nongGeng
      if (scrollTop > 1767) {
        this.activeName = "nongGeng";
      }

      // console.log("window scrollTop:", scrollTop);
      // console.log(
      //   "目标 distOffsetTop:",
      //   distOffsetTop,
      //   document.querySelector("#bannerMenu")
      // );
      // console.log("fixed标识：", this.barFixed);
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.bigGroup {
  padding: 60px 0;
  > .content {
    width: 1400px;
    margin: 0 auto;
    > .banner {
      width: 678px;
      height: 448px;
      vertical-align: top;
    }
    > .layoutRight {
      display: inline-block;
      position: relative;
      // background: red;
      width: 687px;
      height: 455px;
      box-sizing: border-box;
      > .icon {
        position: absolute;
        top: 5px;
        left: 0;
        display: block;
        // margin-top: 5px;
      }
      > .title {
        display: block;
        font-size: 30px;
        font-weight: bold;
        color: $font-grayDarkDD;
        margin-top: 105px;
      }
      > .detail {
        // min-height: 133px;
        line-height: 1.5;
        font-size: 18px;
        color: $font-grayDarkDD;
        margin-top: 22px;
        // background: blue;
      }
      > .btnsWrap {
        margin-top: 24px;
        .rowWrap {
          display: inline-block;
          margin-bottom: 19px;
          > .btn {
            width: 271px;
            height: 64px;
            display: inline-block;
            line-height: 64px;
            border-radius: 50px;
            border: 1px solid $font-grayDarkD;
            color: $font-grayDarkDD;
            text-align: center;
            transition: all 0.2s ease-in;
            &:hover {
              color: $main-white !important;
              background: #51b870;
              border: 1px solid #333;
            }
          }
          > .btn:nth-of-type(1) {
            margin-right: 88px;
          }
        }
      }
    }
  }
}
.layoutLeftWrap.bigGroup {
  > .content {
    > .banner {
      margin-right: 35px;
    }
    > .layoutRight {
      display: inline-block;
      position: relative;
      // background: red;
      width: 687px;
      height: 455px;
      box-sizing: border-box;
      > .icon {
        position: absolute;
        top: 5px;
        left: 0;
        display: block;
        // margin-top: 5px;
      }
      > .title {
        display: block;
        font-size: 30px;
        font-weight: bold;
        color: $font-grayDarkDD;
        margin-top: 105px;
      }
      > .detail {
        // min-height: 133px;
        line-height: 1.5;
        font-size: 18px;
        color: $font-grayDarkDD;
        margin-top: 22px;
        // background: blue;
      }
      > .btnsWrap {
        margin-top: 24px;
        .rowWrap {
          display: inline-block;
          margin-bottom: 19px;
          > .btn {
            width: 271px;
            height: 64px;
            display: inline-block;
            line-height: 64px;
            border-radius: 50px;
            border: 1px solid $font-grayDarkD;
            color: $font-grayDarkDD;
            text-align: center;
          }
          > .btn:nth-of-type(1) {
            margin-right: 88px;
          }
        }
      }
    }
  }
}

.layoutRightWrap.bigGroup {
  background: $main-gray;
  > .content {
    > .banner {
    }
    > .layoutRight {
      margin-right: 35px;
      > .icon {
        top: 0px;
      }
    }
  }
}

.playWrap {
  > .funlandWrap {
    > .content {
      > .banner {
      }
      > .layoutRight {
        > .icon {
        }
        > .title {
          // margin-top: 17px;
        }
        > .detail {
          // background: blue;
        }
        > .btnsWrap {
          .rowWrap {
            display: inline-block;
            margin-bottom: 19px;
            > .btn {
              width: 271px;
              height: 64px;
              display: inline-block;
              line-height: 64px;
              border-radius: 50px;
              border: 1px solid $font-grayDarkD;
              color: $font-grayDarkDD;
              text-align: center;
            }
            > .btn:nth-of-type(1) {
              margin-right: 88px;
            }
          }
        }
      }
    }
  }

  // 3. Farming paradis
  > .farmingWrap {
    > .content {
      > .layoutRight {
        display: inline-block;
        // background: red;
        width: 687px;
        height: 455px;
        box-sizing: border-box;
        > .icon {
          top: -29px;
        }
        > .title {
        }
        > .detail {
          // height: 133px;
          // line-height: 1.5;
          // font-size: 18px;
          // color: $font-grayDarkDD;
          // margin-top: 22px;
          // background: blue;
        }
        > .btnsWrap {
          .rowWrap {
            display: inline-block;
            margin-bottom: 19px;
            > .btn {
              width: 271px;
              height: 64px;
              display: inline-block;
              line-height: 64px;
              border-radius: 50px;
              border: 1px solid $font-grayDarkD;
              color: $font-grayDarkDD;
              text-align: center;
            }
            > .btn:nth-of-type(1) {
              margin-right: 88px;
            }
          }
        }
      }
    }
  }
}

//  公用-------------
.group > .titleWrap {
  position: relative;
  width: 100%;
  height: 101px;
  padding: 119px 0 54px 0;
  // background: $bg-blue;
  .titleCenter {
    position: absolute;
    width: 554px;
    height: 115px;
    left: 0;
    right: 0;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0px 73px;
    background: $main-white;

    > img {
      vertical-align: top;
    }
    .titleLogo {
      position: absolute;
      top: -40px;
    }
    .layout_right {
      position: relative;
      display: inline-block;
      padding-left: 30px;

      > .subtitle {
        position: absolute;
        top: 44px;
        left: 8px;
        z-index: 0;
      }
      > .title {
        display: block;
        color: $main-black;
        font-size: 33px;
        padding-top: 3px;
      }
    }
  }
  > .gap {
    position: absolute;
    width: 100%;
    height: 4px;
    background: $main-greenGrass;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: -2;
  }
}
.contentWrap {
  > .summaryWrap {
    > .layout_left {
      width: 500px;
      height: 378px;
      padding: 49px 45px 30px 25px;
      display: inline-block;
      // background: $main-greenGrass;
      box-sizing: border-box;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      overflow: hidden;
      > .titleWrap {
        margin-bottom: 27px;
        > .title,
        .subtitle {
          display: block;
        }
        > .title {
          font-size: 28px;
          font-weight: bold;
          // color: $main-white;
        }
        > .subtitle {
          font-size: 23px;
          font-weight: bold;
          // color: $font-green;
        }
        > .gap {
          width: 30px;
          height: 2px;
          background: $main-white;
          margin-top: 22px;
        }
      }
      .detailWrap {
        height: 168px;
        font-size: 13px;
        color: $main-white;
        line-height: 2;
        overflow: hidden;
        padding-bottom: 20px;
      }
    }
    > .layout_right {
      // background: red;
      width: calc(100% - 500px);
      height: 378px;
      display: inline-block;
      text-align: right;
    }
  }
}

// 私有--------------------
.playWrap {
  // margin-top: 36px;
  margin-bottom: 100px;
  // border: 1px solid transparent;
  border-bottom: 1px solid transparent;
  > img {
    width: 100%;
  }
  > .bannerWrap {
    position: relative;
    // height:281px;
    > img {
      width: 100%;
      height: 396px;
    }

    .bannerBox {
      position: relative;
      // bottom: 12px;
      width: 100%;
      height: 180px;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;

      > .btnWrap {
        position: absolute;
        width: 1550px;
        height: 180px;
        bottom: 12px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        // z-index:1;
        > .swiper-button-next.swiper-button-white,
        > .swiper-button-prev.swiper-button-white {
          --swiper-navigation-color: #23b2e8;
        }
      }

      > .swiper-container {
        width: 100%;
        height: 180px;

        // border: 1px solid red;
        .swiper-slide {
          text-align: center;
          background-size: cover;
          background-position: center;
        }
      }

      .gallery-thumbs {
        position: relative;
        width: 1400px;
        height: 180px;
        left: 0;
        right: 0;
        margin: 0 auto;
        // bottom: 16px;
        box-sizing: border-box;
        // z-index:2;
        // background: $main-white;
        .swiper-slide {
          // background:$main-white;
          height: 100%;
          // opacity: 0.5;
          text-align: center;
          // line-height: 60px;
          &.liuLiu {
            > .menuThumbWrap {
              border: 8px solid $main-blue;
            }
          }
          &.shiHu {
            > .menuThumbWrap {
              border: 8px solid $main-red;
            }
          }
          &.nongGeng {
            > .menuThumbWrap {
              border: 8px solid $main-greenGrass;
            }
          }
          > a,
          > a:focus,
          > a:hover,
          > a.active {
            color: $font-grayDarkD;
          }

          > .menuThumbWrap {
            position: relative;
            display: block;
            // width: 435px;
            text-align: center;
            overflow: hidden;
            border: 8px solid $main-blue;
            border-radius: 14px;
            cursor: pointer;

            > .imgWrap {
              width: 100%;
              height: 100%;
              // width: 435px;
              height: 123px;

              img {
                width: 100%;
              }
            }
            > .infoWrap {
              position: relative;
              height: 41px;
              padding: 0 9px;
              background: $main-white;

              box-sizing: border-box;
              .title {
                position: absolute;
                display: block;
                width: 274px;
                height: 16px;
                line-height: 16px;
                top: 2px;
                left: 0;
                right: 0;
                font-size: 18px;
                font-weight: bold;
                background: #fff;
                z-index: 0;
                margin: 9px auto 0px auto;
                text-align: center;
              }
              .gap {
                position: absolute;
                width: 388px;
                height: 1px;
                background: $font-grayDark;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                z-index: 0;
              }
            }
          }
        }
        .swiper-slide-thumb-active {
          opacity: 1;
        }
      }
    }

    .fixedMenus {
      position: fixed;
      top: 120px;
      width: 1400px;
      z-index: 99998;
      display: none;
      left: 0;
      right: 0;
      margin: 0 auto;

      &.show {
        display: block;
      }
      > .fixedMenu {
        display: inline-block;
        width: 466px;
        height: 50px;
        line-height: 50px;
        background: $font-grayDarkD;
        font-size: 27px;
        font-weight: bold;
        color: $main-white;
        text-align: center;
        overflow: hidden;

        > img {
          margin-right: 10px;
        }
        &.liuLiuLeYuan {
          border-bottom: 10px solid $main-blue;
          // border-bottom-left-radius: 10px;
          &:hover,
          &.active {
            background: $main-blue;
          }
        }
        &.shiHu {
          border-bottom: 10px solid $main-red;
          &:hover,
          &.active {
            background: $main-red;
          }
        }
        &.nongGeng {
          width: 468px;
          border-bottom: 10px solid $main-greenGrass;
          // border-bottom-right-radius: 10px;
          &:hover,
          &.active {
            background: $main-greenGrass;
          }
        }
      }
    }
    > .menusWrap {
      position: absolute;
      width: 1400px;
      bottom: 23px;
      left: 0;
      right: 0;
      margin: auto;
      > .menuWrap {
        position: relative;
        text-align: center;
        display: inline-block;
        overflow: hidden;

        &.liuliuLeYuan {
          border: 4px solid $main-blue;
        }
        &.shiHu {
          border: 4px solid $main-red;
        }
        &.nongGeng {
          border: 4px solid $main-greenGrass;
          margin-right: 0;
        }
      }

      > .picGroup {
        width: 435px;
        box-shadow: 5px 2px 5px $font-gray;
        border-radius: 10px;
        display: inline-block;
        margin-right: 33px;
        > .imgWrap {
          width: 435px;
          height: 123px;
        }
        > .infoWrap {
          position: relative;
          height: 41px;
          padding: 0 9px;
          background: $main-white;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;

          .title {
            position: absolute;
            display: block;
            width: 154px;
            height: 16px;
            left: 0;
            right: 0;
            font-size: 16px;
            background: #fff;
            z-index: 0;
            margin: 9px auto 0px auto;
            text-align: center;
          }
          .gap {
            position: absolute;
            width: 100%;
            height: 1px;
            background: $font-grayDark;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            z-index: 0;
          }
        }
      }
    }
  }

  > .menusWrap {
    display: flex;
    margin-top: 90px;
    justify-content: space-between;

    .menuWrap {
      position: relative;
      text-align: center;

      .menu {
        width: 285px;
        height: 90px;
        line-height: 90px;
        position: relative;
        border-radius: 53px;
        span {
          text-align: center;
          color: $main-white;
          font-size: 27px;
          font-weight: bold;
        }
        > img {
          position: absolute;
          left: -50px;
          bottom: 0;
        }
      }
      .menu.xiCan {
        background: $main-red;
      }
      .menu.zhongCan {
        background: $main-yellow;
      }
      .menu.hongBei {
        background: $main-blue;
      }
    }

    .menuWrap.xiCan {
      margin-left: 45px;
    }
  }
  // 溜溜乐园
  .liuLiuLeYuanWrap {
    position: relative;
    width: 1400px;
    margin: 0 auto;
    // 溜溜乐园---题目----------
    > .titleWrap {
      .titleCenter {
        background: $main-white;

        .layout_right {
          > .title {
            color: $main-black;
          }
        }
      }
      > .gap {
        background: $main-blue;
      }
    }
    // 溜溜乐园----简介---------
    > .contentWrap {
      > .summaryWrap {
        > .layout_left {
          background: $main-blue;

          > .titleWrap {
            > .title {
              color: $main-white;
            }
            > .subtitle {
              color: $main-purple;
            }
          }
        }
      }
    }
  }
  // 溜溜乐园end----------

  // 石斛文化园
  .shiHuWenHuaYuanWrap {
    position: relative;
    width: 1400px;
    margin: 0 auto;
    > .titleWrap {
      .titleCenter {
        .titleLogo {
          left: 25px;
        }
        .layout_right {
          > .title {
            color: $main-black;
          }
        }
      }
      > .gap {
        background: $main-red;
      }
    }
    // 石斛文化园----简介---------
    > .contentWrap {
      > .summaryWrap {
        > .layout_left {
          background: $main-red;

          > .titleWrap {
            > .title {
              color: $main-white;
            }
            > .subtitle {
              color: $main-orange;
            }
          }
        }
      }
    }
    // 石斛文化园----图片展示---------
  }

  // 农耕乐园
  .nongGengLeYuanWrap {
    position: relative;
    width: 1400px;
    margin: 0 auto;
    > .titleWrap {
      .titleCenter {
        background: $main-white;
        .titleLogo {
          left: 19px;
        }
        > img {
          vertical-align: top;
        }
        .layout_right {
          > .title {
            color: $main-black;
          }
        }
      }
      > .gap {
        background: $main-greenGrass;
      }
    }
    // 农耕乐园----简介---------
    > .contentWrap {
      > .summaryWrap {
        > .layout_left {
          background: $main-greenGrass;
          > .titleWrap {
            > .title {
              color: $main-white;
            }
            > .subtitle {
              color: $font-green;
            }
          }
        }
      }
    }
  }
}
#mliuliuLeYuan:after {
  display: none;
}
#mliuliuLeYuan:hover:after {
  display: none;
}
#mShiHu:after {
  display: none;
}
#mShiHu:hover:after {
  display: none;
}

#mNongGeng:after {
  display: none;
}
#mNongGeng:hover:after {
  display: none;
}

@media (max-width: $screen-sp1-min) {
  .playWrap > .bannerWrap .bannerBox > .btnWrap {
    width: 1300px;
  }
}
</style>
